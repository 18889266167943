import React from 'react';
import Deco2 from '../../../resources/img/deco2.png';
import '../assets/styles.scss';
import CardFaq from './CardFaq';

export default function FAQ({ faqData, type }) {
  return (
    <div className="layout-faq">
      {type !== 'legal' ? (
        <img src={Deco2} alt="decoration" width={240} className="deco2" />
      ) : null}
      <h2 className="title-faq">FAQs</h2>
      <div className="faq-column">
        <div className="left-column">
          {faqData.map((each, i) => {
            return i % 2 === 0 ? (
              <CardFaq question={each.question} answer={each.answer} key={i} />
            ) : null;
          })}
        </div>
        <div className="right-column">
          {faqData.map((each, i) => {
            return i % 2 !== 0 ? (
              <CardFaq question={each.question} answer={each.answer} key={i} />
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}
