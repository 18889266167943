import TagManager from 'react-gtm-module';
import config from '../shop/config/config.json';
import {
  cryptedAuthentication,
  getClearingOfficeRef,
  getEcommerceOffer,
  getMemberId,
  getOffer,
  getOfferId,
} from './catalinaRequests';
import {
  getAndApplyApiConfiguration,
  getDesktopBackgroundLayer,
  getOfferIdFromUrl,
} from './appApiConfiguration';
import { urlReader } from './appApiConfiguration';

import { isEcommerceMode, offerType } from './clientConfig';
import NoOfferFoundError from './errors/NoOfferFoundError';

/**
 * Applies the API configuration for the application.
 * If in ecommerce mode and no member ID is available, performs crypted authentication.
 * Retrieves the offer from the backend based on the offer type.
 * Applies the API configuration to the offer.
 * Retrieves the desktop background layer.
 *
 * @returns {Promise<{offer: Offer, desktopBackgroundImgLayer: string, loaded: boolean}>} The API configuration result.
 */
export async function applyApiConfiguration() {
  if (isEcommerceMode() && !getMemberId()) {
    await cryptedAuthentication();
  }

  // Some shops are still using the legacy offer type.
  const offerPromise =
    offerType === 'receipt' ? getEcommerceOffer() : getOffer(getOfferId());

  try {
    const offerFromBackend = await offerPromise;
    if (offerFromBackend) {
      const offer = getAndApplyApiConfiguration(offerFromBackend);
      const res = await getDesktopBackgroundLayer();
      return {
        offer,
        desktopBackgroundImgLayer: res,
        loaded: true,
      };
    } else {
      throw new NoOfferFoundError('No offer found for the given offer ID');
    }
  } catch (err) {
    throw new Error(err);
  }
}

async function checkIfAppIsProperlyInitialised() {
  try {
    let isInitAlreadyDone = JSON.parse(sessionStorage.getItem('init'));
    let isTeasing = JSON.parse(sessionStorage.getItem('teasing'));
    if (isTeasing) {
      // We reload all infos on teasing page, or if we come from the teasing
      sessionStorage.removeItem('teasing');
      return false;
    }
    if (!isInitAlreadyDone) {
      return false;
    }
    // List all session storage keys
    let keys = Object.keys(sessionStorage);
    if (keys.length <= 1) {
      return false;
    }
    // Check if offer is in session storage and the same
    // as the one in the URL
    const offerIdFromUrl = await getOfferIdFromUrl();
    const offerIdFromSessionStorage = sessionStorage.getItem('offerId');
    if (offerIdFromUrl !== offerIdFromSessionStorage && !isInitAlreadyDone) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Initializes the application and returns the token value.
 * @returns {string} The token value from the URL.
 */
export function initializeAppAndGetToken() {
  let token = '';
  let url = window.location.href;
  let isNotRefund = !url.includes('refund');

  let isInitAlreadyDone = checkIfAppIsProperlyInitialised();
  TagManager.initialize(config.googleTagManagerId);
  if (isNotRefund) {
    token = urlReader();
  }

  if (isEcommerceMode() && !isInitAlreadyDone) {
    sessionStorage.removeItem('offers-' + getClearingOfficeRef());
  }
  sessionStorage.setItem('init', 'true');

  return token;
}
