import React from 'react';
import Button from '../../../components/Button/Button';
import ShareComponent from '../../../components/Share/ShareComponent';
import '../assets/styles.scss';
import { hexToRgb } from '../../../../utils/utils';
import FAQ from './FAQ';
import { faq } from '../../../../shop/config/faq';

export default function LayoutContent({ offer, type }) {
  let video = offer?.actions?.find(
    (item) => item.action_type === 'display_video',
  );

  let offerColor = sessionStorage.getItem('offerBackgroundColor');
  let offerColorWithOpacity = hexToRgb(offerColor?.trim() || null);

  return (
    <div
      className="layout-content"
      style={{
        backgroundColor: offerColorWithOpacity || 'rgba(255, 255, 255, 0.5)',
      }}
    >
      {offer?.CGU ? (
        <div className="container-rule-button">
          <Button
            text={'Voir le règlement du jeu'}
            className="rule-button button-teasing black-button"
            doAction={() => (document.location = offer.CGU)}
          />
        </div>
      ) : null}
      {!!video & (type !== 'legal') ? (
        <div className="layout-video">
          <h2 className="title-video">{video.args.title}</h2>
          <iframe
            title="promo_video"
            className="video"
            width={350}
            height={480}
            src={`https://www.youtube.com/embed/${video.args.url}`}
          />
        </div>
      ) : null}
      <FAQ faqData={faq} />
      <div className="share-layout">
        <ShareComponent type="lose" />
      </div>
    </div>
  );
}
