import React, { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import cardSound from "../../resources/assets/sound/card.mp3";
import winSound from "../../resources/assets/sound/Win.mp3";
import Button from "../../components/Button/Button";

import "./assets/styles.scss";
import {
  getOfferId,
  getOfferOdds,
  getRetailerId,
} from "../../../utils/catalinaRequests";
import Win from "./Win/Win";
import Lost from "./Lost/Lost";
import { useNavigate } from "@reach/router";
import Loading from "../../components/Loading/Loading";
import { useTranslation } from "react-i18next";
import Card from "./components/FlipCard/Card";
import { flipItem } from "../../../shop/config/flipConfig";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import { getGameResult } from "./utils/gameUtils";
import {
  areAllPlayed,
  setResultGameOffer,
  setResultGameOfferEcomm,
} from "../../../utils/utils";
import { isEcommerceMode } from "../../../utils/clientConfig";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import useMobile from "../../../utils/hooks/useMobile";
let cloverImg = require("./../../../shop/assets/img/clover.png");

const REDIRECTING_TIME = 3000;

function FlipGame({ backgroundLayerStyle, loaded, brandLogo }) {
  const [t] = useTranslation("message");
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [winner, setWinner] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [chosenCard, setChosenCard] = useState(null);
  const [isScreenFinished, setScreenFinished] = useState(false);
  let [rewardDesc, setRewardDesc] = useState("");
  let [oneShoot, setOneShoot] = useState(false);
  const [cardSoundPlay] = useSound(cardSound, { volume: 0.5 });
  const [winSoundPlay] = useSound(winSound, { volume: 0.3 });
  const isClicked = useRef(false);
  let [odds, setOdds] = useState(0);
  let oddsLocal = getOfferOdds();

  const isMobile = useMobile();

  useEffect(() => {
    if (areAllPlayed()) {
      navigate("/can-not-play");
    } else {
      getGameResult(setWinner, setAllowed, setRewardDesc);
    }

    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "game_display",
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: "e-commerce",
      },
    });
  }, []);

  useEffect(() => {
    setOdds(oddsLocal);
  }, [oddsLocal]);

  let prevOdds = usePrevious(odds);
  useEffect(() => {
    if (odds === "one" || prevOdds === "one") {
      setOneShoot(true);
    } else if (oneShoot) {
      setOneShoot(false);
    }
  }, [odds]);

  useEffect(() => {
    if (isScreenFinished) {
      setTimeout(() => {
        if (winner && (odds === 0 || !odds)) {
          winSoundPlay();
        }
        setGameEnded(true);
      }, REDIRECTING_TIME);
    }
  }, [isScreenFinished]);

  function play() {
    if (!animated) {
      setAnimated(true);
      if (isEcommerceMode()) {
        setResultGameOfferEcomm();
      } else {
        setResultGameOffer();
      }
      cardSoundPlay();
      setShowCard(true);
      setScreenFinished(true);
    }
  }

  function newGame() {
    setAnimated(false);
    setGameEnded(false);
    setScreenFinished(false);
  }

  function onClick({ id, modeRandom }) {
    if (!isClicked.current) {
      isClicked.current = true;
      const randomNum = Math.floor(Math.random() * 3);
      modeRandom ? setChosenCard(randomNum) : setChosenCard(id);
      play();
    }
  }

  function gameContent() {
    return allowed && loaded ? (
      <div className="game-content">
        <div className="game">
          {isEcommerceMode() && !oneShoot && (
            <div className="subtitle odds-text">
              <img
                src={cloverImg}
                width={30}
                className="clover-game"
                alt="clover"
              />

              {t("game.oddsTitle", { nbChance: odds })}
            </div>
          )}
          {!isMobile ? (
            <div className="subtitle desktop-only">
              <div>{t("game.flip.title")}</div>
              <div>{t("game.flip.title2")}</div>
            </div>
          ) : (
            <div className="title">{t("game.flip.titleMobile")}</div>
          )}
          <div className="card-style">
            {flipItem.map((item, index) => {
              return (
                <Card
                  key={index}
                  chosenCard={chosenCard}
                  item={item}
                  onClick={() => onClick({ id: index })}
                  id={index}
                  brandLogo={brandLogo}
                  showCard={showCard}
                  isWinner={winner && (odds === 0 || !odds)}
                />
              );
            })}
          </div>
          <div className="buttons-area">
            {isMobile && (
              <div className="subtitle mobile-only">
                {t("game.flip.titleMobile2")}
              </div>
            )}
            <Button
              text={t("game.play")}
              disabled={isClicked.current}
              doAction={() => onClick({ modeRandom: true })}
            />
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  function renderedGame() {
    if (!gameEnded) {
      return (
        <SlotMachine
          content={gameContent()}
          backgroundLayerStyle={backgroundLayerStyle}
          flipBg={true}
          flat
        />
      );
    } else {
      if (winner && (odds === 0 || !odds)) {
        return (
          <Win
            backgroundLayerStyle={backgroundLayerStyle}
            rewardDesc={rewardDesc}
          />
        );
      } else {
        return (
          <Lost
            newGame={newGame}
            odds={odds}
            backgroundLayerStyle={backgroundLayerStyle}
          />
        );
      }
    }
  }

  return renderedGame();
}

export default FlipGame;
