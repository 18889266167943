import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getActiveSpotlight, getOfferBackgroundColor } from '../../../utils/catalinaRequests';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TIMEOUT = 5000;

function SpotlightPopin({ offerId, onPlayClick }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spotlight, setSpotlight] = useState(null);
  const [counter, setCounter] = useState(TIMEOUT / 1000);
  const [t] = useTranslation('message');
  const background = getOfferBackgroundColor();

  function onInternalPlayClick() {
    setIsOpen(false);
    onPlayClick && onPlayClick();
  }

  useEffect(() => {
      let intervalId;
      let timeoutId;

      async function fetchActiveSpotlight() {
        try {
          const activeSpotlight = await getActiveSpotlight(offerId);

          if (activeSpotlight) {
            setSpotlight(activeSpotlight);
            setIsOpen(true);

            // Start the countdown
            intervalId = setInterval(() => {
              setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            // Close the popin after 5 seconds
            timeoutId = setTimeout(() => {
              onInternalPlayClick();
              clearInterval(intervalId);
              setCounter(TIMEOUT / 1000);
            }, TIMEOUT);
          }
        } catch (error) {
          console.error(error);
        }
      }

      fetchActiveSpotlight();

      // Clean up the interval and timeout on unmount
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, []);

  return (
    <Modal className="modal-spotlight" style={{
      content: {
        backgroundColor: background
      }
    }} isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <div className="content">
        {spotlight && <img src={spotlight.picture_url} alt={spotlight.name} />}

        <Button
          text={t('landing.play')}
          doAction={onInternalPlayClick} />

        <span className="playing-in">
          {t("landing.gameIsPlayingIn", { count: counter })}
        </span>
      </div>
    </Modal>
  );
}

SpotlightPopin.propTypes = {
  offerId: PropTypes.string.isRequired,
  onPlayClick: PropTypes.func,
};


export default SpotlightPopin;