import React from "react";
import Receipt from "./components/Receipt";
import Pin from "./components/Pin";
import { receiptInStoreMode } from "../../../utils/catalinaRequests";

function InStore() {
  let isReceiptMode = receiptInStoreMode();
  return isReceiptMode ? (
    <Receipt />
  ) : (
    <Pin />
  );
}

export default InStore;
