import shopConfig from '../shop/config/config.json';
import { getHolderRef, receiptInStoreMode } from './catalinaRequests';

export function withRefundFlow() {
  return enableIban() || enablePaypal() || enableLater();
}
export function withDynamicReward() {
  return !!shopConfig.enableDynamicReward;
}

export function enableIban() {
  return !!shopConfig.enableRefundFlow.iban;
}

export function enablePaypal() {
  return !!shopConfig.enableRefundFlow.paypal;
}

export function enableLater() {
  return !!shopConfig.enableRefundFlow.later;
}

export function collectInfoBefore() {
  return shopConfig.getUserInfo.type === 'before';
}

export function collectInfoAfter() {
  return shopConfig.getUserInfo.type === 'after';
}

export function enableEmail() {
  return shopConfig.getUserInfo.email;
}

export function enableName() {
  return shopConfig.getUserInfo.name;
}
export function enableStreet() {
  return shopConfig?.getUserInfo?.street;
}

export function isEcommerceMode() {
  return getHolderRef() && !shopConfig.isModeInStore && !receiptInStoreMode();
}

export function isModeInStore() {
  return shopConfig.isModeInStore;
}
export function isReceiptMode() {
  return receiptInStoreMode() && shopConfig.isModeInStore && !getHolderRef();
}

export function isPinMode() {
  return shopConfig.isModeInStore && !getHolderRef() && !receiptInStoreMode();
}

export const hasHomeSpotlight = !!shopConfig.homeSpotlight;
export const landingNoOptin = !!shopConfig.landingNoOptin;

/**
 * Gets the offer type from the shop configuration.
 * If the offer type is not specified, it defaults to "default".
 * @returns {("default"|"legacy")}  The offer type.
 */
export const offerType = shopConfig.offerType || 'default';

export const isCollectEnabled =
  shopConfig.collectFields && shopConfig.collectFields.length > 0;
export const isEmailCollected =
  shopConfig.collectFields && shopConfig.collectFields.includes('email');
export const isNameCollected =
  shopConfig.collectFields && shopConfig.collectFields.includes('name');
export const isCityCollected =
  shopConfig.collectFields && shopConfig.collectFields.includes('city');
export const isNewsletterCollected =
  shopConfig.collectFields &&
  shopConfig.collectFields.includes('news_optin_partners');
export const isLegalCollected =
  shopConfig.collectFields && shopConfig.collectFields.includes('optin_legal');
export const newsletterText = shopConfig.collectNewsletterMessage;
export const defaultBackgroundColor = shopConfig.defaultBackgroundColor;
export const errorMessage = shopConfig.ErrorMessage;
