import React, { useState } from 'react';
import './assets/style.scss';
import { useTranslation } from 'react-i18next';
import SlotMachine from '../../../components/Slotmachine/SlotMachine';
import Button from '../../../components/Button/Button';
import { navigate } from '@reach/router';
import {
  cryptedAuthentication,
  getMemberId,
  sendPin,
} from '../../../../utils/catalinaRequests';
import InputText from '../../../components/Form/Input/InputText';
import Loading from '../../../components/Loading/Loading';
import ErrorMessage from '../../../components/Form/Error/ErrorMessage';
import config from '../../../../shop/config/config.json';
import { gameRedirect } from '../../../../utils/utils';
import { collectInfoBefore, enableEmail } from '../../../../utils/clientConfig';
import { useForm } from 'react-hook-form';
import { onEnter } from '../../../../utils/utils';

function Pin() {
  const [t] = useTranslation('message');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  let isEnable = isValid && isDirty;

  function onSubmit(data) {
    setLoading(true);
    setErrorMessage();
    let email;
    if (enableEmail() && collectInfoBefore()) {
      email = data.email;
    } else {
      email = `${Date.now()}@shopanplay.it`;
    }

    if (!getMemberId()) {
      cryptedAuthentication(email).then(() => {
        sendPin(data.pin)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              navigate(gameRedirect(config.gameType));
            }
          })
          .catch((error) => {
            const { status, error: responseError } = error.responseJSON;
            if ([404, 422].includes(status)) {
              setErrorMessage(responseError);
            } else if (status === 429) {
              navigate('/can-not-play');
            }
            setLoading(false);
          });
      });
    } else {
      sendPin(data.pin)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            navigate(gameRedirect(config.gameType));
          }
        })
        .catch((error) => {
          const { status, error: responseError } = error.responseJSON;
          if ([404, 422].includes(status)) {
            setErrorMessage(responseError);
          } else if (status === 429) {
            navigate('/can-not-play');
          }
          setLoading(false);
        });
    }
  }

  function Pin() {
    return (
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
        <div className="subtitle">{t('instore.pin.desc')}</div>
        {!loading ? (
          <div className="container-line-input">
            <InputText
              name="pin"
              type="text"
              required
              placeholder={t('instore.pin.pin')}
              autoComplete="off"
              errorMessage={t('refund.intro.error')}
              register={register}
              watch={watch}
            />
            {errorMessage && <ErrorMessage message={errorMessage} />}
          </div>
        ) : (
          <Loading />
        )}
        <div className="button-area">
          <Button
            text={t('general.validate')}
            disabled={!isEnable}
            type="submit"
          />
        </div>
      </form>
    );
  }

  return <SlotMachine content={Pin()} />;
}

export default Pin;
