import React, { useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';

import './assets/style.scss';
import { ReactComponent as UnionIcon } from '../../../../game/resources/img/union.svg';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../../../utils/hooks/useOnClickOutside';
import variables from '../../../../shop/assets/styles/variables.scss';

const InputText = ({
  defaultValue,
  type,
  placeholder,
  autoComplete,
  errorMessage,
  displayErrors,
  register,
  required,
  name,
  pattern,
  watch,
  setValue,
  label,
  autocompleteData,
  setError,
  ...rest
}) => {
  const [t] = useTranslation('message');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [fuzzyResults, setFuzzyResults] = useState([]);
  const textInputRef = useRef(null);
  const [hasValueFromResults, setHasValueFromResults] = useState(false);

  function handleBlur() {
    if (autocompleteData && !hasValueFromResults) {
      setIsAutocompleteOpen(false);
      if (!isCurrentValueInResults()) {
        setValue(name, undefined);
        setError(name, {
          type: 'manual',
          message: t('collectInfo.noResult'),
        });
      }
    }
  }

  useOutsideClick({ ref: textInputRef, handler: handleBlur });

  let value = watch(name);

  function isCurrentValueInResults() {
    if (!value) return false;
    const [postalCode, city] = value.split(' - ');
    return autocompleteData.some(
      (result) => result.city === city && result.postalCode === postalCode,
    );
  }

  useEffect(() => {
    if (autocompleteData && value && !isCurrentValueInResults()) {
      // Specific to aldi
      const hasAldi = value && value.toLowerCase().indexOf('aldi') !== -1;
      if (hasAldi) {
        // trim aldi
        value = value.toLowerCase().replace('aldi', '').trim();
      }
      const fuse = new Fuse(autocompleteData, {
        keys: ['postalCode', 'city', 'address'],
        includeScore: true,
        threshold: 0.4,
      });
      let results = fuse.search(value).slice(0, 5);
      // if no results, take the first 5
      if (results.length === 0 && hasAldi) {
        results = fuse.search('a').slice(0, 5);
      }
      setHasValueFromResults(false);
      setFuzzyResults(results);
      setIsAutocompleteOpen(true);
    }
  }, [value, autocompleteData, hasValueFromResults]);

  const textlabel = (required) => {
    return required ? `${placeholder}${t('general.needed')}` : placeholder;
  };

  const handleResultClick = (result) => {
    setValue(
      name,
      `${result.item.postalCode} - ${result.item.city} - ${result.item.address}`,
    );
    setIsAutocompleteOpen(false);
    setHasValueFromResults(true);
  };

  return (
    <div className="text-input" ref={textInputRef}>
      <div className="top-placeholder">{value ? textlabel(required) : ''}</div>
      <input
        {...register(name, { required: required, pattern: pattern })}
        defaultValue={defaultValue}
        type={type}
        autoComplete={autoComplete}
        placeholder={textlabel(required)}
        {...rest}
      />
      {isAutocompleteOpen ? (
        <div className="autocomplete-list">
          {fuzzyResults.length === 0 && (
            <div className="autocomplete-item">{t('collectInfo.noResult')}</div>
          )}
          {fuzzyResults.map((result, index) => (
            <div
              className="autocomplete-item"
              key={index}
              onClick={() => handleResultClick(result)}
            >
              <span className="autocomplete-top">
                {result.item.postalCode} - {result.item.city}
              </span>
              <span className="autocomplete-bottom">{result.item.address}</span>
            </div>
          ))}
        </div>
      ) : null}

      {displayErrors && (
        <div className="input-error-message">
          {errorMessage}
          <UnionIcon
            fill={variables?.['color-secondary']}
            stroke={variables?.['color-secondary']}
          />
        </div>
      )}
      {label ? <label>{label}</label> : null}
    </div>
  );
};

export default InputText;
