import React from 'react';
import { Link, useParams } from '@reach/router';
import legalJson from '../../../../shop/legals/legal.json';
import './assets/styles.scss';
import SlotMachine from '../../../components/Slotmachine/SlotMachine';
import FAQ from '../../Teasing/components/FAQ';

function Legal({ token }) {
  const params = useParams();
  function getLegalPageHtml(legalType) {
    switch (legalType) {
      case 'generalConditions':
        return legalJson.generalConditions;
      case 'legalNotices':
        return legalJson.legalNotices;
      case 'dataProtection':
        return legalJson.dataProtection;
      default:
        return legalJson.help;
    }
  }
  let returnLink = `/?info=${token}`;

  const htmlPage = getLegalPageHtml(params.legalType);

  function Legal() {
    return (
      <div className="legal-container">
        <div className="legal-content">
          <Link className="back-button" to={returnLink} />
          {params.legalType === 'help' ? (
            <FAQ faqData={legalJson.help} type="legal" />
          ) : (
            <div className="legal-text">
              <div dangerouslySetInnerHTML={{ __html: htmlPage }} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return <SlotMachine content={<Legal />} isLegal />;
}

export default Legal;
