import React from "react";

import "./assets/style.scss";
import { Link } from "@reach/router";
import { getOfferBackgroundColor } from "../../../utils/catalinaRequests";
import { defaultBackgroundColor } from "../../../utils/clientConfig";

function Button({ text, disabled, doAction, to, type, className }) {
  const color = getOfferBackgroundColor() || defaultBackgroundColor;
  if (to) {
    if (to.includes("http")) {
      return (
        <a
          className={`play-button  ${disabled ? "disabled" : ""} ${className}`}
          href={to}
          style={{
            color
          }}
        >
          {text}
        </a>
      );
    }
    return (
      <Link
        className={`play-button  ${disabled ? "disabled" : ""} ${className}`}
        to={to}
        style={{
          color
        }}
      >
        {text}
      </Link>
    );
  }
  return (
    <button
      className={`play-button  ${disabled ? "disabled" : ""} ${className}`}
      style={{
        color
      }}
      type={type ? type : "button"}
      onClick={doAction}
    >
      {text}
    </button>
  );
}

export default Button;
