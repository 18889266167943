import React, { useEffect } from "react";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import Button from "../../components/Button/Button";
import Replay from "../../components/Modal/Replay";
import shopConfig from "../../../shop/config/config.json";
import ShareComponent from "../../components/Share/ShareComponent";

function Success() {
  useEffect(() => {
    //block previous click
    window.history.pushState({}, "");
  });

  function SuccessContent() {
    return (
      <>
        <Replay />
        <div className="title">{shopConfig.SuccessMessage.title}</div>
        <div className="description">{shopConfig.SuccessMessage.desc}</div>
        {shopConfig?.enableShare && <ShareComponent type="win" />}
        <div className="button-area">
          <Button
            text={shopConfig.SuccessMessage.txtCTA}
            doAction={() =>
              (document.location = shopConfig.SuccessMessage.urlCTA)
            }
          />
        </div>
      </>
    );
  }

  return (
    <SlotMachine
      content={SuccessContent()}
    />
  );
}

export default Success;
