import React, { useState, useEffect, useRef } from "react";

import { getRotationDegrees } from "./utils";
import { useTranslation } from "react-i18next";
import {
  RouletteContainer,
  RotationContainer,
  RouletteSelectorImage,
} from "./styles";
import {
  DEFAULT_BACKGROUND_COLORS,
  DEFAULT_TEXT_COLORS,
  DEFAULT_OUTER_BORDER_COLOR,
  DEFAULT_OUTER_BORDER_WIDTH,
  DEFAULT_INNER_RADIUS,
  DEFAULT_INNER_BORDER_COLOR,
  DEFAULT_INNER_BORDER_WIDTH,
  DEFAULT_RADIUS_LINE_COLOR,
  DEFAULT_RADIUS_LINE_WIDTH,
  DEFAULT_FONT_SIZE,
  DEFAULT_TEXT_DISTANCE,
  DEFAULT_SPIN_DURATION,
} from "./strings";
import WheelCanvas from "./WheelCanvas";
import variables from "../../../../../shop/assets/styles/variables.scss";
import "./../../assets/styles.scss";
import useMobile from "../../../../../utils/hooks/useMobile";
let marker = require("./../Wheel/marker.png");

const STARTED_SPINNING = "started-spinning";
const START_SPINNING_TIME = 2600;
const CONTINUE_SPINNING_TIME = 750;
const STOP_SPINNING_TIME = 8000;

export function Wheel({
  mustStartSpinning,
  prizeNumber,
  data,
  brandLogo,
  doAction,
  sizeWheel,
  onStopSpinning = () => null,
  backgroundColors = DEFAULT_BACKGROUND_COLORS,
  textColors = DEFAULT_TEXT_COLORS,
  outerBorderColor = DEFAULT_OUTER_BORDER_COLOR,
  outerBorderWidth = DEFAULT_OUTER_BORDER_WIDTH,
  innerImage,
  innerRadius = DEFAULT_INNER_RADIUS,
  innerBorderColor = DEFAULT_INNER_BORDER_COLOR,
  innerBorderWidth = DEFAULT_INNER_BORDER_WIDTH,
  radiusLineColor = DEFAULT_RADIUS_LINE_COLOR,
  radiusLineWidth = DEFAULT_RADIUS_LINE_WIDTH,
  fontSize = DEFAULT_FONT_SIZE,
  perpendicularText,
  textDistance = DEFAULT_TEXT_DISTANCE,
  spinDuration = DEFAULT_SPIN_DURATION,
}) {
  const [t] = useTranslation("message");
  const [wheelData, setWheelData] = useState([...data]);
  const [startRotationDegrees, setStartRotationDegrees] = useState(0);
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const normalizedSpinDuration = Math.max(0.01, spinDuration);

  const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
  const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
  const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;

  const totalSpinningTime =
    startSpinningTime + continueSpinningTime + stopSpinningTime;

  const mustStopSpinning = useRef(false);

  const isMobile = useMobile();

  useEffect(() => {
    const dataLength = data.length;
    const wheelDataAux = [{ option: "" }];
    for (let i = 0; i < dataLength; i++) {
      wheelDataAux[i] = {
        ...data[i],
        img: data[i].img && brandLogo ? brandLogo : data[i].img,
        text: data[i].text ? t(`game.${data[i].text}`) : null,
        style: {
          backgroundColor: i % 2 === 0 ? variables.primary : "white",
          textColor: "black",
        },
      };
    }
    setWheelData([...wheelDataAux]);
    setIsDataUpdated(true);
  }, [data, backgroundColors, textColors]);

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
      const finalRotationDegreesCalculated = getRotationDegrees(
        prizeNumber,
        data.length
      );
      setFinalRotationDegrees(finalRotationDegreesCalculated);
    }
  }, [mustStartSpinning]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
    }
  }, [hasStoppedSpinning]);

  const startSpinning = () => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;
    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
        onStopSpinning();
      }
    }, totalSpinningTime);
  };

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING;
    }
    return "";
  };

  if (!isDataUpdated) {
    return null;
  }

  let markerSizeSize = sizeWheel / 10;

  return (
    <RouletteContainer sizeWheel={sizeWheel}>
      <RotationContainer
        className={getRouletteClass()}
        startSpinningTime={startSpinningTime}
        continueSpinningTime={continueSpinningTime}
        stopSpinningTime={stopSpinningTime}
        startRotationDegrees={startRotationDegrees}
        finalRotationDegrees={finalRotationDegrees}
      >
        <WheelCanvas
          width={"700"}
          height="700"
          sizeWheel={sizeWheel}
          data={wheelData}
          outerBorderColor={outerBorderColor}
          outerBorderWidth={outerBorderWidth}
          innerImage={innerImage}
          innerRadius={innerRadius}
          innerBorderColor={innerBorderColor}
          innerBorderWidth={innerBorderWidth}
          radiusLineColor={radiusLineColor}
          radiusLineWidth={1.5}
          fontSize={fontSize}
          perpendicularText={perpendicularText}
          textDistance={textDistance}
          doAction={doAction}
        />
      </RotationContainer>
      <RouletteSelectorImage
        src={marker}
        alt="roulette-static"
        className={`${mustStartSpinning ? "marker-anim" : ""}`}
        width={isMobile ? 25 : markerSizeSize}
      />
    </RouletteContainer>
  );
}
