import { useEffect, useState } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

export function LandingChecks({ CGULink, hasSecondOptin, onCheckChange }) {
  const [t] = useTranslation('message');
  const [checkedFirst, setCheckedFirst] = useState(false);
  const [checkedSecond, setCheckedSecond] = useState(false);

  useEffect(() => {
    // check if all checkboxes are checked
    if (checkedFirst && (checkedSecond || !hasSecondOptin)) {
      onCheckChange(true);
    } else {
      onCheckChange(false);
    }
  }, [checkedFirst, checkedSecond]);

  return (
    <div className="checkbox-wrap">
      <div className="agree">
        <Checkbox checked={checkedFirst} setChecked={setCheckedFirst} />
        <div>
          {t('landing.optin1')}
          {CGULink ? (
            <a href={CGULink} target="_blank" rel="noreferrer">
              {t('landing.conditions')}
            </a>
          ) : (
            t('landing.conditions')
          )}
        </div>
      </div>

      {hasSecondOptin ? (
        <div className="agree">
          <Checkbox checked={checkedSecond} setChecked={setCheckedSecond} />
          <div>
            {t('landing.optin2')}{' '}
            <Link to="legal/dataProtection">{t('landing.informations')}</Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}
