import React from 'react';
import {
  flipAnimation,
  logoReverseColour,
  cardReverseColour,
  losingIcon,
} from '../../../../../shop/config/flipConfig';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import transparentClover from '../../../../../shop/assets/img/transparentClover.svg';
import blackClover from '../../../../../game/resources/img/blackClover.svg';
import { defaultWinningIcon } from '../../../../../shop/config/flipConfig';

import cherry from '../../../../../shop/assets/img/ico2.png';
import './assets/styles.scss';

const Card = ({ onClick, showCard, chosenCard, id, isWinner, brandLogo }) => {
  const [t] = useTranslation('message');
  const winningLogo = brandLogo || defaultWinningIcon;
  const losingLogo = losingIcon || cherry;
  let isEnable = chosenCard === id && showCard;
  let animationType =
    flipAnimation === 'vertical' && isEnable
      ? { transform: 'rotateX(180deg)' }
      : flipAnimation === 'horizontal' && isEnable
      ? { transform: 'rotateY(180deg)' }
      : {};

  return (
    <div className={`card ${showCard && 'showed'}`} onClick={onClick}>
      <div className="content" style={animationType}>
        <div
          className="front"
          style={{
            backgroundColor: cardReverseColour,
          }}
        >
          <ReactSVG
            src={blackClover}
            beforeInjection={(svg) => {
              svg.classList.add('svg-classname-behind');
              svg.setAttribute('fill', logoReverseColour);
            }}
          />
          <ReactSVG
            src={transparentClover}
            beforeInjection={(svg) => {
              svg.classList.add('svg-classname-front');
            }}
          />
        </div>
        <div className="back">
          <img
            className="corner-logo-card"
            src={isWinner ? winningLogo : losingLogo}
            alt="little-img"
          />
          <img
            className="corner-logo-card"
            src={isWinner ? winningLogo : losingLogo}
            alt="little-img"
          />
          {isWinner ? (
            <img
              src={winningLogo}
              className="img-center-card"
              alt="winning-icon"
            />
          ) : (
            <div className="card__lost">
              <img src={losingLogo} alt="little-img" />
              <div className="back-text">{t('game.lose')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
