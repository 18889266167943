import { hexToRgb } from '../../../utils/utils';
import './assets/style.scss';
import config from './../../../shop/config/config.json';
import Logo from '../Logo/Logo';

function SlotMachine({ content, flat = false, isLegal = false }) {
  let offerColor = sessionStorage.getItem('offerBackgroundColor');
  let offerColorWithOpacity = hexToRgb(
    offerColor?.trim() || config.defaultBackgroundColor,
  );
  return (
    <div className="layout-page">
      <Logo displayLogo />
      <div className="display-screen">
        <div
          className="game-screen"
          style={{
            backgroundColor: !flat ? offerColorWithOpacity : 'transparent',
            height: isLegal ? 600 : 510,
          }}
        >
          <div className="game-screen-content">{content}</div>
        </div>
      </div>
    </div>
  );
}

export default SlotMachine;
