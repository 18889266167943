import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import leverSound from "../../resources/assets/sound/lever.mp3";
import Button from "../../components/Button/Button";
import {
  getHolderRef,
  getOfferId,
  getRetailerId,
  receiptInStoreMode,
  getOfferOdds,
} from "../../../utils/catalinaRequests";
import Win from "./Win/Win";
import Lost from "./Lost/Lost";
import winSound from "../../resources/assets/sound/Win.mp3";
import { useNavigate } from "@reach/router";
import Loading from "../../components/Loading/Loading";
import { useTranslation } from "react-i18next";
import "./assets/styles.scss";
import { Wheel } from "./components/Wheel";
import { wheelItems } from "../../../shop/config/wheelConfig.js";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import spinningSound from "../../resources/assets/sound/spinning-wheel.mp3";
import { getGameResult } from "./utils/gameUtils";
import {
  areAllPlayed,
  setResultGameOffer,
  setResultGameOfferEcomm,
} from "../../../utils/utils";
import { isEcommerceMode } from "../../../utils/clientConfig";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import useMobile from "../../../utils/hooks/useMobile.js";
let cloverImg = require("./../../../shop/assets/img/clover.png");

const REDIRECTING_TIME = 3000;

function WheelGame({ backgroundLayerStyle, loaded, brandLogo }) {
  const [t] = useTranslation("message");
  const isMobile = useMobile();
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const [animated, setAnimated] = useState(false);
  let [winner, setWinner] = useState(false);
  let [gameEnded, setGameEnded] = useState(false);
  const [leverPulled] = useSound(leverSound, { volume: 0.05 });
  const [winSoundPlay] = useSound(winSound, { volume: 0.3 });
  let [oneShoot, setOneShoot] = useState(false);
  let [rewardDesc, setRewardDesc] = useState("");
  const [spinningSoundPlay, { stop }] = useSound(spinningSound, {
    volume: 0.3,
    playbackRate: 0.3,
  });
  let [odds, setOdds] = useState(0);
  let oddsLocal = getOfferOdds();

  useEffect(() => {
    if (areAllPlayed()) {
      navigate("/can-not-play");
    } else {
      getGameResult(setWinner, setAllowed, setRewardDesc);
    }

    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "game_display",
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: "e-commerce",
      },
    });
  }, []);

  useEffect(() => {
    setOdds(oddsLocal);
  }, [oddsLocal]);

  let prevOdds = usePrevious(odds);
  useEffect(() => {
    if (odds === "one" || prevOdds === "one") {
      setOneShoot(true);
    } else if (oneShoot) {
      setOneShoot(false);
    }
  }, [odds]);

  function play() {
    if (!animated) {
      spinningSoundPlay();
      setAnimated(true);
      if (getHolderRef() || receiptInStoreMode()) leverPulled();
      if (!isEcommerceMode()) {
        setResultGameOffer();
      }
    }
  }

  const onFinished = () => {
    // stop the animation and the sound
    setAnimated(false);
    stop();
    setTimeout(() => {
      if (winner && (odds === 0 || !odds)) {
        winSoundPlay();
      }
      setGameEnded(true);
    }, REDIRECTING_TIME);
    if (isEcommerceMode()) {
      setResultGameOfferEcomm();
    }
  };

  let wheelSize =
    window.innerHeight / 2.2 < 250
      ? 250
      : window.innerHeight / 2.2 > 350
      ? 350
      : 300;

  let spinSize = wheelSize / 4.5;

  let spinStyle = isMobile
    ? { width: 66, height: 66, borderRadius: 33 }
    : {
        width: spinSize,
        height: spinSize,
        borderRadius: spinSize / 2,
      };

  function newGame() {
    setAnimated(false);
    setGameEnded(false);
  }
  function gameContent() {
    return allowed && loaded ? (
      <div className="game-content">
        <div className="game">
          {isEcommerceMode() && !oneShoot && (
            <div className="subtitle odds-text">
              <img
                src={cloverImg}
                width={30}
                className="clover-game"
                alt="clover"
              />

              {t("game.oddsTitle", { nbChance: odds })}
            </div>
          )}
          {isMobile ? (
            <div className="title">{t("game.wheel.titleMobile")}</div>
          ) : (
            <div className="subtitle">{t("game.title")}</div>
          )}

          <div className="wheel-container">
            <div className="spinButton" onClick={play}>
              <div style={spinStyle} className="spinBtnElt">
                <div className="spinTxt">{t("game.wheel.spin")}</div>
              </div>
            </div>

            <Wheel
              mustStartSpinning={animated}
              innerBorderWidth={2}
              innerRadius={30}
              outerRadius={50}
              innerImage={"../../../../shop/assets/img/spin.png"}
              radiusLineWidth={0}
              prizeNumber={winner ? 1 : 0}
              data={wheelItems}
              onStopSpinning={onFinished}
              perpendicularText={false}
              brandLogo={brandLogo}
              sizeWheel={isMobile ? 300 : wheelSize}
            />
          </div>
          <div className="buttons-area">
            <Button text={t("game.play")} disabled={animated} doAction={play} />
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  function renderedGame() {
    if (!gameEnded) {
      return (
        <SlotMachine
          content={gameContent()}
          backgroundLayerStyle={backgroundLayerStyle}
          flat
        />
      );
    } else {
      if (winner && (odds === 0 || !odds)) {
        return (
          <Win
            backgroundLayerStyle={backgroundLayerStyle}
            rewardDesc={rewardDesc}
          />
        );
      } else {
        return (
          <Lost
            backgroundLayerStyle={backgroundLayerStyle}
            newGame={newGame}
            odds={odds}
          />
        );
      }
    }
  }
  return renderedGame();
}

export default WheelGame;
