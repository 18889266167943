import React from 'react';

import './assets/style.scss';
import { ReactComponent as CheckSVG } from './assets/images/checkMark.svg';

function Checkbox({ checked, setChecked, label, ...rest }) {
  const id = rest.id ?? Math.random().toString(36).substring(2, 9); // Generate a random ID

  return (
    <div className="checkbox-wrapper">
      <div
        onClick={() => setChecked(!checked)}
        className={`checkbox ${checked ? 'checked' : ''}`}
      >
        <input
          type="checkbox"
          id={id}
          aria-checked={checked}
          defaultChecked={checked}
          className="visually-hidden"
        />
        {checked ? <CheckSVG /> : null}
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default Checkbox;
