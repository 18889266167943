import React, { useState, useEffect } from 'react';
import './assets/style.scss';
import { ReactComponent as DeleteSVG } from '../../../resources/img/delete.svg';
import { ReactComponent as UploadSVG } from '../../../resources/img/upload.svg';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SlotMachine from '../../../components/Slotmachine/SlotMachine';
import Button from '../../../components/Button/Button';
import { navigate } from '@reach/router';
import {
  cryptedAuthentication,
  sendReceipt,
} from '../../../../utils/catalinaRequests';
import ProgressBar from '@ramonak/react-progress-bar';
import { useStopwatch } from 'react-timer-hook';
import config from '../../../../shop/config/config.json';
import { gameRedirect } from '../../../../utils/utils';
import { Loader } from './Loader/Loader';
import ErrorMessage from '../../../components/Form/Error/ErrorMessage';
import {
  collectInfoBefore,
  enableEmail,
  enableName,
  isCollectEnabled,
} from '../../../../utils/clientConfig';
import { onEnter } from '../../../../utils/utils';

function Receipt() {
  const [t] = useTranslation('message');
  const hiddenFileInput = React.useRef(null);
  const [enable, setEnable] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [base64File, setBase64File] = useState();
  const [receiptId, setReceiptId] = useState();
  const [loading, setLoading] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState();
  const { start, seconds } = useStopwatch({ autoStart: false });
  const hasEnabledFields = enableName() || enableEmail();
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (base64File) {
      setEnable(true);
    }
    if (uploadedFileName) {
      setUploadedFileName(uploadedFileName);
    }
  }, [base64File, uploadedFileName]);

  useEffect(() => {
    if (seconds <= config.timerPostReceipt) {
      let progress = Number(
        ((100 / config.timerPostReceipt) * seconds).toFixed(),
      );
      setProgressBarValue(progress);
    }
  }, [seconds]);

  /**
   * Determines the email to use for authentication.
   *
   * @param {Object} data - The form data.
   * @returns {string} The email to use for authentication.
   */
  function determineEmail(data) {
    if (enableEmail() && collectInfoBefore()) {
      return data.email;
    } else {
      return `${Date.now()}@shopanplay.it`;
    }
  }

  /**
   * Handles the response from the sendReceipt function.
   *
   * @param {Object|string} resp - The response from the sendReceipt function.
   */
  async function handleReceiptResponse(resp) {
    setLoading(false);
    setProgressBarValue(0);

    if (resp === 'validate') {
      // If the response is "validate", navigate to the game
      navigate(gameRedirect(config.gameType));
    } else {
      if (resp.receipt_id) {
        // If a receipt ID is returned, reset the uploaded file and set the new receipt ID
        setUploadedFileName(undefined);
        setBase64File(undefined);
        setReceiptId(resp.receipt_id);
      }

      // Set the error message
      setErrorMessage(t('instore.receipt.reupload'));
    }
  }

  async function onSubmit(data) {
    if (!enable) return;

    setEnable(false);
    setErrorMessage(undefined);
    setLoading(true);

    try {
      // Authenticate the user
      if (!isCollectEnabled) {
        // Determine the email to use for authentication
        const email = determineEmail(data);
        await cryptedAuthentication(email);
      }
      start();

      // Send the receipt
      const resp = await sendReceipt(base64File, receiptId);

      // Handle the response
      await handleReceiptResponse(resp);
    } catch (err) {
      setLoading(false);
      setProgressBarValue(0);
      setUploadedFileName(undefined);
      setBase64File(undefined);
      if (
        err &&
        err.responseJSON &&
        err.responseJSON.error &&
        err.responseJSON.statusCode === 422
      ) {
        if (
          err.responseJSON.error.indexOf(
            'Vous avez déjà sélectionné cette offre',
          ) !== -1
        ) {
          setErrorMessage(t('instore.receipt.alreadyPlayed'));
        } else {
          setErrorMessage(t('instore.receipt.reupload'));
        }
      } else {
        if (err && err.isGlobalError) {
          setErrorMessage(err.message);
        } else {
          setErrorMessage(t('instore.receipt.reupload'));
        }
      }
    }
  }

  /**
   * Converts a file to base64 format.
   * @param {File} file - The file to be converted.
   * @returns {Promise<string>} - A promise that resolves with the base64 representation of the file.
   */
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  /**
   * Handles the file read event.
   *
   * @param {Event} event - The file read event.
   * @returns {Promise<void>} - A promise that resolves when the file is read.
   */
  async function handleFileRead(event) {
    const file = event.target.files[0];

    // Check the file type
    const acceptedFileTypes = [
      'image/jpeg',
      'image/png',
      'image/bmp',
      'image/tiff',
    ];
    if (!acceptedFileTypes.includes(file.type)) {
      setErrorMessage(t('instore.receipt.invalidFileType'));
      return;
    }

    // Check the file size (10MB = 10 * 1024 * 1024 bytes)
    const maxFileSize = 10 * 1024 * 1024;
    if (file.size > maxFileSize) {
      setErrorMessage(t('instore.receipt.fileTooLarge'));
      return;
    }

    setUploadedFileName(file.name);
    const base64 = await convertBase64(file);
    let base64Trim = base64.split(',')[1];
    setBase64File(base64Trim);
    setErrorMessage(undefined);
  }

  /**
   * Clears the uploaded file and resets the state.
   */
  function emptyUploadedFile() {
    setUploadedFileName(undefined);
    setBase64File(undefined);
    setEnable(false);
  }

  function onUploadFile() {
    hiddenFileInput.current.click();
  }

  function Receipt() {
    return (
      <form
        className="form-receipt"
        onSubmit={handleSubmit(onSubmit)}
        onKeyUp={(e) => onEnter(e)}
      >
        <div className="subtitle">{t('instore.receipt.desc')}</div>
        <div className="layout-receipt-info">
          {Array.from({ length: 3 }, (v, i) => (
            <div className="container-receipt-info">
              <img
                src={require('./../../../resources/img/check-square.png')}
                alt="check-decoration"
              />
              <div
                className="test-info-receipt"
                dangerouslySetInnerHTML={{
                  __html: t(`instore.receipt.info.${i + 1}`, {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </div>
          ))}
        </div>

        {!loading ? (
          <>
            <input
              id="receipt"
              style={{ display: 'none' }}
              key={uploadedFileName}
              ref={hiddenFileInput}
              name="receipt"
              type="file"
              placeholder={'receipt'}
              autoComplete="off"
              encType="multipart/form-data"
              onChange={handleFileRead}
            />
            <div className="layout-button">
              <div className="inputFile">
                {!uploadedFileName ? (
                  <div onClick={onUploadFile} className="uploadFileReceipt">
                    <div className="uploadImgReceipt">
                      <UploadSVG width={30} height={30} />
                    </div>
                    <div className="uploadLabel">
                      {t('instore.receipt.upload')}
                    </div>
                  </div>
                ) : (
                  <div className="uploadedFileText">
                    {uploadedFileName}
                    <div className="deleteImg" onClick={emptyUploadedFile}>
                      <DeleteSVG />
                    </div>
                  </div>
                )}
              </div>

              <div className="button-area">
                <Button
                  text={t('general.validate')}
                  disabled={
                    !isValid || (hasEnabledFields && !isDirty) || !enable
                  }
                  type="submit"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Loader />
            <ProgressBar
              bgColor="#023587"
              completed={progressBarValue}
              className="progress-bar"
            />
          </>
        )}

        {errorMessage && (
          <ErrorMessage
            message={errorMessage || t('instore.receipt.reupload')}
          />
        )}
      </form>
    );
  }

  return <SlotMachine content={Receipt()} />;
}

export default Receipt;
