import { useCallback, useEffect, useRef } from "react"

function useCallbackRef(callback, deps = []) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args) => callbackRef.current?.(...args), deps)
}

export function useOutsideClick(props) {
  const { ref, handler, enabled = true } = props
  const savedHandler = useCallbackRef(handler)

  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
  })

  const state = stateRef.current

  useEffect(() => {
    if (!enabled) return
    const onPointerDown = (e) => {
      if (isValidEvent(e, ref)) {
        state.isPointerDown = true
      }
    }

    const onMouseUp = (event) => {
      if (state.ignoreEmulatedMouseEvents) {
        state.ignoreEmulatedMouseEvents = false
        return
      }

      if (state.isPointerDown && handler && isValidEvent(event, ref)) {
        state.isPointerDown = false
        savedHandler(event)
      }
    }

    const onTouchEnd = (event) => {
      state.ignoreEmulatedMouseEvents = true
      if (handler && state.isPointerDown && isValidEvent(event, ref)) {
        state.isPointerDown = false
        savedHandler(event)
      }
    }

    const doc = getOwnerDocument(ref.current)
    doc.addEventListener("mousedown", onPointerDown, true)
    doc.addEventListener("mouseup", onMouseUp, true)
    doc.addEventListener("touchstart", onPointerDown, true)
    doc.addEventListener("touchend", onTouchEnd, true)

    return () => {
      doc.removeEventListener("mousedown", onPointerDown, true)
      doc.removeEventListener("mouseup", onMouseUp, true)
      doc.removeEventListener("touchstart", onPointerDown, true)
      doc.removeEventListener("touchend", onTouchEnd, true)
    }
  }, [handler, ref, savedHandler, state, enabled])
}

function isValidEvent(event, ref) {
  const target = event.target

  if (target) {
    const doc = getOwnerDocument(target)
    if (!doc.contains(target)) return false
  }

  return !ref.current?.contains(target)
}

function getOwnerDocument(node) {
  return node?.ownerDocument ?? document
}
