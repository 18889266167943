import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import closeIcon from '../../../shop/assets/img/close.png';
import config from '../../../shop/config/config.json';
import { getOffer } from '../../../utils/catalinaRequests';
import './assets/styles.scss';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { gameRedirect } from '../../../utils/utils';
import { isEcommerceMode } from '../../../utils/clientConfig';
const INITIAL_COUNT = 6;

function Replay() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [urlImageOffer, setUrlImageOffer] = React.useState('');
  const [timeRemaining, setTimeRemaning] = useState(INITIAL_COUNT);
  const [t] = useTranslation('message');

  function closeModal() {
    setIsOpen(false);
  }
  function afterOpenModal() {
    setTimeout(() => {
      setIsOpen(false);
      goNextGame();
    }, 5000);
  }

  function openModal() {
    setIsOpen(true);
  }
  function goNextGame() {
    navigate(gameRedirect(config.gameType));
    window.location.reload(false);
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useEffect(() => {
    const gamesPlayedList = JSON.parse(
      sessionStorage.getItem(
        'offers-' + sessionStorage.getItem('clearingOfficeRef'),
      ),
    );
    let isCurrentOfferAlreadyPlayed;

    if (isEcommerceMode()) {
      isCurrentOfferAlreadyPlayed = gamesPlayedList?.filter((g) => {
        return g.id === +sessionStorage.getItem('offerId') && g.odds <= 0;
      }).length;
    } else {
      isCurrentOfferAlreadyPlayed = !gamesPlayedList?.filter((g) => {
        return g.id === +sessionStorage.getItem('offerId') && g.played;
      }).length;
    }
    if (!!isCurrentOfferAlreadyPlayed) {
      let nextOffer;
      if (isEcommerceMode()) {
        nextOffer = gamesPlayedList?.filter((g) => g.odds !== 0)[0];
      } else {
        nextOffer = gamesPlayedList?.filter((g) => !g.played)[0];
      }

      if (nextOffer) {
        getOffer(nextOffer.id.toString())
          .then((offer) => {
            if (offer) {
              sessionStorage.setItem('offerId', nextOffer.id);
              const id = setTimeout(() => {
                let gameBanner = offer?.carousel_pictures.filter((b) =>
                  b.includes('Game-banner'),
                )[0];
                setUrlImageOffer(gameBanner);
                openModal();
              }, 1000);

              return () => {
                clearTimeout(id);
              };
            }
          })
          .catch((err) => {
            console.error('Error:', err);
            setUrlImageOffer(null);
          });
      }
    }
  }, []);

  useInterval(() => {
    if (timeRemaining > 0) {
      setTimeRemaning(timeRemaining - 1);
    }
  }, 1000);

  return (
    <div className={'replay-container'}>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        className="modal-replay"
      >
        <div className="button-close-modal" onClick={closeModal}>
          <img src={closeIcon} alt="" />
        </div>

        <h2 className="title-modal">{t('game.playAgainTitle')}</h2>
        <h3 className="subtitle-modal">{t('game.playAgainSubtitle')}</h3>
        {urlImageOffer && urlImageOffer !== '' && (
          <img
            src={urlImageOffer}
            alt=""
            className="img-modal"
            onClick={goNextGame}
          />
        )}
        <div className="button-play-modal" onClick={goNextGame}>
          {t('landing.play')}
        </div>
        <p>{t('game.modalChance.timer', { sec: timeRemaining })}</p>
      </Modal>
    </div>
  );
}

export default Replay;
