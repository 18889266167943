import React from 'react';
import './assets/style.scss';
import { ReactComponent as UnionIcon } from '../../../../game/resources/img/union.svg';
import variables from '../../../../shop/assets/styles/variables.scss';

function ErrorMessage({ message }) {
  return (
    <div className="generic-error-message">
      <UnionIcon
        fill={variables?.['color-secondary']}
        stroke={variables?.['color-secondary']}
      />
      {message}
    </div>
  );
}

export default ErrorMessage;
